import React from 'react'
import Section from '../Section'
import SectionHeader from '../SectionHeader'
import Newsletter from '../Newsletter'
import './styles.scss'

function NewsletterSection(props) {
  return (
    // <Section color={props.color} size={props.size}>
    <div className="container newsletter">
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        centered={false}
        size={4}
      />
      <div id="Form-Container">
        <Newsletter
          parentColor={props.color}
          buttonText={props.buttonText}
          inputPlaceholder={props.inputPlaceholder}
          subscribedMessage={props.subscribedMessage}
          size={4}
          formName={props.formName}
        />
      </div>
    </div>
    // </Section>
  )
}

export default NewsletterSection
