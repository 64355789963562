import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import NewsletterSection from './../NewsletterSection'
import './styles.scss'

function CtaSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <div className="columns is-vcentered has-text-centered-mobile is-centered is-variable is-8">
          <div className="column is-narrow">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={3}
            />
          </div>
          <div className="column is-narrow">
            <NewsletterSection
              centered={false}
              size={4}
              buttonText={props.buttonText}
              subscribedMessage={props.subscribedMessage}
              inputPlaceholder={'Email Address'}
              color={props.color}
              formName={props.formName}
            />
            {/* <SectionButton
              parentColor={props.color}
              size="medium"
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </SectionButton> */}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CtaSection
