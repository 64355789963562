/* eslint-disable no-undef */
import React from 'react'
import SectionButton from './../SectionButton'
import { track } from '../../utils/analytics'
import './styles.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      subscribed: false,
    }
    this.setEmail = this.setEmail.bind(this)
    this.setSubscribed = this.setSubscribed.bind(this)
  }

  setEmail = email => {
    this.setState({
      email: email,
    })
  }

  setSubscribed = subscribed => {
    this.setState({
      subscribed: subscribed,
    })
  }

  // Handle form submission
  handleSubmit = e => {
    // If field this.errors then show them
    if (this.state.email) {
      this.setSubscribed(true)
      this.props.onSubscribed && this.props.onSubscribed()
      // !subscribedMessage = Redirect to signup page for pricing page forms
      // if (!this.props.subscribedMessage) {
      //   window.open('https://app.akutehealth.com/signup', '_self')
      // }
      const gclid = sessionStorage.getItem('gclid')
      track({
        event: 'demo_request',
        page: window.location.href,
        form: this.props.formName,
      })

      // Newsletter.subscribe({ email })
      fetch(`${process.env.GATSBY_EMAIL_ENDPOINT}/email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': this.props.formName,
          gclid: gclid,
          ...this.state,
        }),
      })
        .then(() => {
          // alert(
          //   'Thanks for requesting a demo. We will reach out to you shortly.'
          // )
          // subscribedMessage = Redirect to demo request for request demo forms
          // if (this.props.subscribedMessage) {
          window.open(
            `https://share.hsforms.com/10vdmS-E4QaS6dstkaTGx2w2tpkv?utm_source=web-${window.location.pathname}&email=${this.state.email}`,
            '_blank',
            'noopener noreferrer'
          )
          // }
        })
        .catch(error => {
          // subscribedMessage = Redirect to demo request for request demo forms
          // if (this.props.subscribedMessage) {
          window.open(
            `https://share.hsforms.com/10vdmS-E4QaS6dstkaTGx2w2tpkv?utm_source=web-${window.location.pathname}&email=${this.state.email}`,
            '_blank',
            'noopener noreferrer'
          )
          // }
          // alert(error)
        })
    }
  }

  render() {
    return (
      <>
        {!this.state.subscribed && (
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleSubmit()
            }}
            name={this.props.formName}
            // data-netlify="true"
            // data-netlify-honeypot="bot-field"
            method="POST"
          >
            <div className="field is-grouped">
              <div className="control is-expanded">
                <input type="hidden" name="bot-field" />
                <input
                  type="hidden"
                  name="form-name"
                  value={this.props.formName}
                />
                <input
                  className={`input is-${this.props.size}`}
                  type="email"
                  placeholder={this.props.inputPlaceholder}
                  onChange={event => this.setEmail(event.target.value)}
                />
              </div>
              <div className="control">
                <SectionButton
                  parentColor={this.props.parentColor}
                  size={this.props.size}
                  onClick={this.props.buttonOnClick}
                >
                  {this.props.buttonText}
                </SectionButton>
              </div>
            </div>
          </form>
        )}
        {this.state.subscribed && this.props.subscribedMessage}
      </>
    )
  }
}

export default Newsletter
