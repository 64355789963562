import React from 'react'
import PropTypes from 'prop-types'
import CenteredColumns from '../CenteredColumns'
import Section from '../Section'
import SectionHeader from '../SectionHeader'
import './styles.scss'

export default class ComparisonCards extends React.Component {
  render() {
    const tiers = this.props.data

    function getFeatures(index) {
      if (index === 0) {
        return (
          <ul className="list-unstyled">
            {tiers.inHouseFeatures.map((feature, index) => {
              // let itemClass = ''
              // if (index === 0) {
              //   itemClass = 'good'
              // } else {
              //   itemClass = 'bad'
              // }
              return (
                <React.Fragment>
                  <li
                    className="good"
                    key={feature}
                    // style={{ margin: 'auto 0px' }}
                  >
                    {feature}
                  </li>
                  <br />
                </React.Fragment>
              )
            })}
          </ul>
        )
      } else if (index === 1) {
        return (
          <ul className="list-unstyled">
            {tiers.akuteHealthFeatures.map(feature => {
              return (
                <React.Fragment>
                  {/* <div className="row">
                  <i class="fas fa-check-circle"></i> */}
                  <li
                    className="good"
                    key={feature}
                    // style={{ margin: 'auto 0px' }}
                  >
                    {feature}
                  </li>
                  <br />
                  {/* </div> */}
                </React.Fragment>
              )
            })}
          </ul>
        )
      } else if (index === 2) {
        return (
          <ul className="list-unstyled">
            {tiers.othersFeatures.map(feature => {
              return (
                <React.Fragment>
                  <li
                    className="good"
                    key={feature}
                    // style={{ margin: 'auto 0px' }}
                  >
                    {feature}
                  </li>
                  <br />
                </React.Fragment>
              )
            })}
          </ul>
        )
      }
    }

    return (
      <Section color={this.props.color} size={this.props.size}>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
          integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
          crossorigin="anonymous"
        />
        <SectionHeader
          title={this.props.title}
          subtitle={this.props.subtitle}
          centered={true}
          size={3}
        />
        <CenteredColumns className="container">
          {tiers.choiceType.map((_, index) => {
            return (
              <div
                className="column compare-column is-half-tablet is-one-third-desktop"
                key={index}
              >
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      {tiers.choiceType[index]}
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                      {/* <CardSubtitle tag="h5" style={{fontWeight: "bold", textAlign: "center"}}>{tiers.prices[index]}</CardSubtitle> */}
                      {/* <br /> */}
                      {getFeatures(index)}
                      {/* <EmailForm styles={styles} name={tiers.choiceType[index]}>{tiers.ctas[index]}</EmailForm> */}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </CenteredColumns>
      </Section>
    )
  }
}

ComparisonCards.propTypes = {
  data: PropTypes.object.isRequired,
  styles: PropTypes.object,
}
