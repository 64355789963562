import React from 'react'
import CtaSection from '../components/CtaSection'
import Navbar from '../components/Navbar'
import Divider from '../components/Divider'
import Footer from '../components/Footer'
import SEO from '../components/seo'
import ComparisonCards from '../components/ComparisonCards'
import VideoSection from '../components/VideoSection'

function HowItWorksPage({ data, location }) {
  return (
    <>
      <Navbar color="white" spaced={true} data={data.allContentfulNavbar} />
      <SEO title="How It Works" />
      <ComparisonCards
        color="white"
        size="small"
        title="How it Works"
        subtitle={
          data.allContentfulHowItWorksPage.edges[0].node.subtitle.subtitle
        }
        data={data.allContentfulHowItWorksPage.edges[0].node}
      />
      <VideoSection
        color="white"
        size="small"
        title="How to Get Started in Just a Few Minutes"
        subtitle=""
        embedUrl="https://www.youtube.com/embed/IEuqJgO-TtU"
      />
      <CtaSection
        color="primary"
        size="medium"
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        formName="how-it-works"
        // subscribedMessage="Thanks! We will reach out to you shortly."
        // buttonOnClick={() => {
        //   router.push('/pricing')
        // }}
      />
      <Divider color="light" />
      <Footer
        color="light"
        size="medium"
        logo={data.allContentfulNavbar.edges[0].node.brandLogo}
        description={data.allContentfulFooter.edges[0].node.description}
        copyright={
          '© ' +
          data.allContentfulFooter.edges[0].node.companyName +
          ` ${new Date().getFullYear()}`
        }
        socialPages={data.allContentfulFooter.edges[0].node.socialPages}
        socialImages={data.allContentfulFooter.edges[0].node.socialImages}
        driftAppId={data.allContentfulFooter.edges[0].node.driftAppId}
      />
    </>
  )
}

export default HowItWorksPage

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulHowItWorksPage {
      edges {
        node {
          choiceType
          inHouseFeatures
          akuteHealthFeatures
          othersFeatures
          subtitle {
            subtitle
          }
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
