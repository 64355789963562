import React from 'react'
import './styles.scss'

function VideoEmbed(props) {
  return (
    <div className="video-div">
      <iframe
        className="video-iframe"
        src={props.url}
        allowFullScreen={true}
        frameBorder={0}
      />
    </div>
  )
}

export default VideoEmbed
